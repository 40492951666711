body {
	background-color: white;

	font-family: Helvetica, Arial, sans-serif;
	font-size: 11pt;
	line-height: 1.5em;
}

img {
	border: none;
}
input {
    width: 40%;
    min-width: 200px;
    border-radius: 10px;
    border: 2px solid brown;
    padding: 10px;
    font-size: 14px;
}
h1, h2, h3 ,a{
	font-family: Georgia, Times, serif;
	color: brown;

	margin-top: 0;
	padding-top: 0;
}

h1 {
	 font-size: 1.3em;
	font-style:italic;
	font-size: larger; }
h2 { font-size: 1.2em; }
h3 { font-size: 1.1em; }

div.wrapper {
	
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

div.wrapper1 {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	
}
div.wrapper3 {
	width: 1800px;
	margin-left: auto;
	margin-right: auto;
	background-color: burlywood;
}
div.flex{
	display: flex;
	justify-content: center;

}

div#header {
	background-color: burlywood;
	height: 100px;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	display: flex;
	justify-content: center;
	
	
}
/* div#logo {

	padding-left: 10px;
	padding-top: 10px;
	border: none;

} */

/* banner image needs to snap to edge */
img#logo {
	padding-left: 10px;
	border: none;
	width:100%;
	height: 100%;
	justify-content: center;
	display: flex;
}

img#banner {
	float: right;
}


ul.nav{
	font-style:italic;
	font-size: larger;
	font-weight: 800;
}
p.note {
	font-size: 0.8em;
	line-height: 1.2em;
}

table#awards td:first-child {
	width: 53%;
}

/* NAV BAR STYLE */

/* kludged up for IE7 compatibility */

div#nav {
	clear: both;
	font-family: Arial, Helvetica, sans-serif;
	margin-top: 5px;
	padding-top: 5px;
	height: 2em;

	background-color: white;
	color:brown;

}

div#nav a {
	color: brown;
	background-color: white;
}

ul.nav, ul.sub , ul.a {
	margin: 0px;
	padding: 0px;
	cursor: default;
	list-style-type: none;
}

ul.nav {
	margin-top: 2px;
}

li.top {
	display: inline;
	position: relative;
	padding: 2px 25px;
}

li.top:first-child {
	margin-left: 1em;
	padding-left: 1em;
}

ul.sub {
	display: none;
	position: absolute;
	top: 1.6em;
	left: 0px;
	min-width: 10em;
	font-size: 0.85em;
	text-align: center;
}

li.top:hover ul {
	display: block;
}

ul.sub a {
	display: block;
	line-height: 1.1em;
	padding: 0.5em 10px;
}

/* regular text in top-level menu */
li.top, li.top>a {
	color: brown;
	background-color: white;
}

/* hover text in top-level menu */
ul.nav>li:hover, ul.nav>li:hover>a, ul.nav>li>a:hover {
	color: brown;
	background-color: white;
}

/* regular text in dropdown area  */
ul.sub li, ul.sub li a {
	color: brown;
	background-color: white;
}

/* hover text in dropdown area  */
ul.nav ul li:hover a, ul.nav ul li a:hover {
	color: brown;
	background-color: white;
}

/* dropdown box area */
ul.sub {
	border: 3px solid brown;
}

/* END NAV BAR STYLE */

div#content {
	margin-top: 5px;
}


th {
	padding-left: 5px;
	padding-right: 5px;
}
th.first {
	padding-right: 5px;
}

p#footer {
	clear: both;

	color: #282828;
	background-color: black;

	font-size: 0.8em;
	text-align: right;
	vertical-align: top;
	position:absolute;
	margin-top: 5px;
	padding-right: 5px;
}
.tablediv{
	color:white;
	background-color: brown;

}

table.compet_venue, table.compet_result, table.dance_result {
	width: 75%;
	margin-bottom: 2em;
}

table.compet_venue th:first-child {
	width: 80%;
}

table.compet_result th:first-child {
	width: 15%;
}

table.dance_result th:first-child {
	width: 35%;
}

table.compet_venue caption, table.compet_result caption, table.dance_result caption {
	font-weight: bold;
}
