.App {
  font-family: sans-serif;
  position: relative;
  width: 80vw;
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
  color: burlywood;
}
#logtxt{
  padding-top: 10px;
}
.heading {
  color: brown;
  border-bottom: 1px solid burlywood;
  margin-bottom: 30px;
  padding-bottom: 10px;
}
input {
  width: 40%;
  min-width: 200px;
  border-radius: 10px;
  border: 2px solid brown;
  padding: 10px;
  font-size: 14px;
}
.textbox {
  width: 40%;
  min-width: 200px;
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
}
.contactButton{
  background: beige;
  color: brown;
  border: none;
  padding: 2px;
  margin-left: 0px;
}
.contactButton hover{
  background: beige;
  color: brown;
  border: none;
  

}
button {
  height: 40px;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  user-select: none;
  font-weight: 600;
  border-radius: 4px;
  color: brown;
  border: 1px solid burlywood;
  background-color: burlywood;
  cursor: pointer;
  margin: 0px 15px;
  min-width: 100px;
}


table {
  width: 100%;
  padding: 1em;
  margin: 20px 0;
  border-collapse: collapse;
  box-shadow: 0 1px 2px 0 #c6e8c9;
}

thead {
  background-color: beige;
  font-size: 16px;
 }


thead tr {
  position: sticky;
  top: 0;
  text-align: center;
  padding: 8px;
}

th {
  text-align: center;
  padding: 10px;
  background-color: 1px solid #ffffff;
  color: brown;
  position: sticky;
  top: 0;
  height: 30px;
}
td {
  text-align: center;
  padding: 10px;
}

tr.first {
  height: 30px;
  /* background-color: #e0e0e0; */
}
tr.second {
  height: 30px;
  background-color: beige;

}

tr:nth-child(even) {
  background-color: #e0e0e0;
}

.sticky_col_head {
  position: -webkit-sticky;
  position: sticky;
  z-index: 9999;
  left:0;
  background-color: white;
}

.sticky_col_body {
  position: -webkit-sticky;
  position: sticky;
  z-index: 9998;
  left:0;
}
@media screen and (max-width: 920px){
  .sticky_col_body {
		position: relative;
		z-index: 0;
		left:10;}
    div#header {
      width: 100%;
      padding-top: 0px;
      justify-content: left;
    }
    #logotxt{
      padding-bottom: 30px;
      padding-top: 50px;
  
    }
	  }

@media screen and (max-width: 400px) {
	 
	div#header {
		width: 100%;
		padding-top: 0px;
justify-content: left;
	}
	#logotxt{
		padding-bottom: 30px;
		padding-top: 50px;

	}
	

	
}
@media screen and (min-width: 400px)  and (max-width: 720){
	 
	div#header {
			
		padding-top: 0px;
    justify-content: left;

	}
	#logotxt{
		padding-bottom: 30px;
		padding-top: 30px;
    justify-content: left;

	}
	
}
@media screen and (min-width: 720px)  and (max-width: 1080){
	 
	div#header h1 {
		padding-top: 8px;
	}
	div#header {
			
		padding-top: 8px;
    justify-content: left;
	}
	#logotxt{
		padding-top: 130px;
    justify-content: left;

	}
	
}