div#header {
	background-color: burlywood;
   
	padding-left: 8px;
	padding-right: 4px;

	padding-top: 8px;

	height: 150px;
}

div#header h1 {
	margin-bottom: 0.3em;
	align-items: center;
	width: 100%;
}
div.pagination {
	align-content: center;
	position:absolute;	
	display: inline-block;	
}
div#header h3 {
	color: #401;
	font-size: 1.2em;
	margin-top: 0;
}

div#header p {
	font-size: 0.95em;
}

div#banner {
	
	float: right;
	padding: 0px;
	margin: 0px;
	margin-left: 4px;
	height: 200px;
}

div#banner img {
	margin: 0px;
	padding: 0px;
	padding-right: 4px;
}

div#content {
	clear: both;
	overflow: auto;
	margin-top: 5px;
	padding-top: 5px;
	color: brown;
	background-color: white;
	border-radius: 10px;
	-webkit-border-radius: 10px;
}

div.column {
	width: 24%;
	margin-left: 3%;
	float: left;
	font-family: Arial, Helvetica, sans-serif;
}

div.column h3 {
	font-size: 1.1em;
	color: #DDD;
}

div.column img {
	text-align: center;
}

div.column:first-child {
	margin-left: 1%;
	width: 15%;
	padding-top: 50px;
}

div.column a {
	color: white;
	text-decoration: none;
}
.form-control{
	width: 50%;
}


